<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- /Brand logo-->
      <!-- <div class="d-flex flex-row-reverse">
        <b-link class="brand-logo">
          <b-img height="30" weight="30" :src="appLogoImage" alt="logo" />
          <h2 class="brand-text text-primary ml-1">MARKETPLACE</h2>
        </b-link>
      </div> -->
      <!-- Login-->
      <b-col
        lg="6"
        offset="3"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 col-4"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            <b-img
              center
              height="100%"
              class="flex"
              weight="100%"
              :src="appLogoImage"
              alt="logo"
            />
          </b-card-title>
          <b-card-text class="mb-2">
            <div class="notice_login info_login">
              <p>Only authorised users are permitted access to CMS login.</p>
            </div>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group
                label="Username or Email Address"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link>
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules=""
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="warning"
                @click="validationForm"
                class="right"
                block
              >
                Log in
              </b-button>
            </b-form>
          </validation-observer>
          <div class="divider my-2">
            <div class="divider-text">or Connect With</div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              type="submit"
              variant="info"
              @click="loginSSO"
              class="right"
              block
            >
              Single Sign On
            </b-button>
          </div>
          <div v-if="showSpinner" class="d-flex justify-content-center mt-5">
            <b-spinner variant="primary" label="Loading..." />
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// eslint-disable-next-line import/no-cycle
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      showSpinner: false,
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLogin: true,
    };
  },
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return { appLogoImage };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  watch: {},
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.showSpinner = true;
          store
            .dispatch("authenticate/authenticateUserLocal", {
              email: this.userEmail,
              password: this.password,
              isLogin: this.isLogin,
            })
            .then((result) => {
              this.showSpinner = false;
              if (result.success) {
                this.$router.push("/").catch(() => {});
              }
            })
            .catch((e) => {
              this.showSpinner = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${e.data.error.message}`,
                  icon: "BellIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    loginSSO() {
      return this.$auth.loginWithRedirect();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.notice_login {
  position: relative;
  margin: 1em;
  background: #eee;
  padding: 1em 1em 1em 1.5em;
  border-left: 4px solid #ddd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.notice_login:before {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: -17px;
  background-color: #ddd;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  font-family: Georgia;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}
.info_login:before {
  content: "i";
  background-color: #f44336;
}
.notice_login p {
  margin: 0;
  color: #f44336;
  font-weight: 700;
}
</style>
